var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "버튼 컴포넌트", height: "700px" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [_c("c-btn", { attrs: { label: "기본", name: "col1" } })],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-btn", {
                  attrs: { label: "아이콘", icon: "save", name: "col1" },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-btn", {
                  attrs: {
                    disabled: true,
                    label: "disable",
                    icon: "save",
                    name: "col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-btn", {
                  attrs: {
                    color: "amber",
                    label: "색상",
                    icon: "save",
                    name: "col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-btn", {
                  attrs: {
                    color: "primary",
                    label: "저장",
                    icon: "save",
                    name: "col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-btn", {
                  attrs: {
                    color: "red",
                    label: "삭제",
                    icon: "delete_forever",
                    name: "col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-btn", {
                  attrs: {
                    color: "accent",
                    label: "추가",
                    icon: "add_circle",
                    name: "col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-btn", {
                  attrs: {
                    color: "accent",
                    label: "등록",
                    icon: "add_circle",
                    name: "col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-btn", {
                  attrs: {
                    color: "info",
                    label: "검색",
                    icon: "search",
                    name: "col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-btn", {
                  attrs: {
                    color: "purple",
                    label: "초기화",
                    icon: "autorenew",
                    name: "col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-btn", {
                  attrs: {
                    color: "black",
                    label: "검은색",
                    icon: "save",
                    name: "col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-btn", {
                  attrs: { isIcon: true, icon: "save", name: "col1" },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-btn", {
                  attrs: {
                    showLoading: false,
                    label: "로딩 없애기",
                    icon: "save",
                    name: "col1",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }