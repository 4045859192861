<template>
  <q-form ref="editForm">
    <c-card title="버튼 컴포넌트" height="700px" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-btn
            label="기본"
            name="col1">
          </c-btn>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-btn
            label="아이콘"
            icon="save"
            name="col1">
          </c-btn>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-btn
            :disabled="true"
            label="disable"
            icon="save"
            name="col1">
          </c-btn>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-btn
            color="amber"
            label="색상"
            icon="save"
            name="col1">
          </c-btn>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-btn
            color="primary"
            label="저장"
            icon="save"
            name="col1">
          </c-btn>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-btn
            color="red"
            label="삭제"
            icon="delete_forever"
            name="col1">
          </c-btn>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-btn
            color="accent"
            label="추가"
            icon="add_circle"
            name="col1">
          </c-btn>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-btn
            color="accent"
            label="등록"
            icon="add_circle"
            name="col1">
          </c-btn>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-btn
            color="info"
            label="검색"
            icon="search"
            name="col1">
          </c-btn>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-btn
            color="purple"
            label="초기화"
            icon="autorenew"
            name="col1">
          </c-btn>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-btn
            color="black"
            label="검은색"
            icon="save"
            name="col1">
          </c-btn>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-btn
            :isIcon="true"
            icon="save"
            name="col1">
          </c-btn>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-btn
            :showLoading="false"
            label="로딩 없애기"
            icon="save"
            name="col1">
          </c-btn>
        </div>
      </template>
    </c-card>
  </q-form>
</template>

<script>
export default {
  name: 'button',
  data() {
    return {
      editable: true,
      data: {
        col1: null,
      },
      comboItems: [
        { code: '1', codeName: '1' },
        { code: '2', codeName: '2' },
        { code: '3', codeName: '3' },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>
